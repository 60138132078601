<template>
  <div>
    <b-card>

      <h2 class="mb-2">
        SMS
      </h2>

      <b-row>

        <b-col
          cols="12"
          class="w-100 mb-2"
        >
          <p v-html="content" />
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="w-100 mb-2"
        >
          <b-form-group
            label="Sender"
          >
            <b-form-input
              :value="sender"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="w-100 mb-2"
        >
          <b-form-group
            label="Recipient SMS"
          >
            <b-form-input
              :value="recipient"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="w-100 mb-2"
        >
          <b-form-group
            label="Subject"
          >
            <b-form-input
              :value="subject"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="isButtonDisabled"
            @click="resent"
          >
            Resend This SMS
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="cancel"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import storeModule from '@/views/admin/sms-audit/smsAuditStoreModule'
import useSmsAuditList from '@/views/admin/sms-audit/sms-audit-list/useSmsAuditList'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      smsLogID: router.currentRoute.params.id,
      content: '',
      sender: '',
      recipient: '',
      subject: '',
      isButtonDisabled: false,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-sms-audit'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      resentSms,
      fetchSmsLogDetail,
    } = useSmsAuditList()

    return {
      resentSms,
      fetchSmsLogDetail,
    }
  },
  async created() {
    if (this.smsLogID) {
      await this.fetchSmsLogDetail(this.smsLogID).then(response => {
        this.content = response.body
        this.sender = response.from
        this.recipient = response.to
        this.subject = response.subject
      })
    }
  },
  methods: {
    async resent() {
      this.isButtonDisabled = true
      await this.resentSms(this.smsLogID).then(() => {
        this.isButtonDisabled = false
        this.cancel()
      })
    },
    cancel() {
      this.$router.push({ name: 'admin-sms-audit' })
    },
  },
}
</script>

<style lang="scss">

</style>
