import { ref, watch } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useSmsAuditList(root) {
  // Use toast
  const toast = useToast()

  const refSmsAuditListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'recipient', label: 'Recipient SMS', sortable: true },
    { key: 'status', label: 'status', sortable: true },
    { key: 'subject', label: 'subject', sortable: true },
    { key: 'user_type', label: 'User Type', sortable: true },
    { key: 'trigger', label: 'trigger', sortable: true },
    { key: 'timestamp', label: 'timestamp', sortable: true },
  ]

  const defaultPerPage = Number(localStorage.getItem('smsAuditPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const filterTimestamp = ref('')
  const filterTrigger = ref('')
  const filterUserType = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const clearFilters = () => {
    filterTimestamp.value = ''
    filterTrigger.value = ''
    filterUserType.value = ''
  }

  const refetchData = () => {
    refSmsAuditListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, filterTimestamp, filterTrigger, filterUserType],
    ([currentPageVal, perPageVal, searchQueryVal, filterTimestampVal, filterTriggerVal, filterUserTypeVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, filterTimestampPrevVal, filterTriggerPrevVal, filterUserTypePrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-sms-audit', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-sms-audit', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-sms-audit', 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterTimestampVal !== filterTimestampPrevVal) {
        pushStateFiltersParams(root, 'admin-sms-audit', 'filterTimestamp', filterTimestampVal)
      }
      if (filterTriggerVal !== filterTriggerPrevVal) {
        pushStateFiltersParams(root, 'admin-sms-audit', 'filterTrigger', filterTriggerVal)
      }
      if (filterUserTypeVal !== filterUserTypePrevVal) {
        pushStateFiltersParams(root, 'admin-sms-audit', 'filterUserType', filterUserTypeVal)
      }
      refetchData()
    })

  const fetchSmsAuditList = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }
    if (filterTimestamp.value) {
      payloadData.createdAt = filterTimestamp.value
    }
    if (filterTrigger.value) {
      payloadData.trigger = filterTrigger.value
    }
    if (filterUserType.value) {
      payloadData.userType = filterUserType.value
    }
    if (searchQuery.value) {
      payloadData.search = searchQuery.value
    }
    store
      .dispatch('app-sms-audit/fetchSmsAuditList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching SMS audits' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resentSms = ID => axios
    .get(`/auth/resend-sms/${ID}`)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'SMS was sent successfully',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error sending SMS',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchSmsLogDetail = ID => axios
    .get(`/auth/sms-logs/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching SMS log detail',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  return {
    fetchSmsAuditList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    filterTimestamp,
    filterTrigger,
    filterUserType,
    sortBy,
    totalItems,
    isSortDirDesc,
    refSmsAuditListTable,

    resentSms,
    refetchData,
    clearFilters,
    fetchSmsLogDetail,
  }
}
